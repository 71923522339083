import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';
import lenses from './lenses';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, lenses]
};

export default menuItems;
